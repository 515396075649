import React from 'react';
import * as DialogPrimitive from '@radix-ui/react-dialog';

import {
  Carousel,
  CarouselContent,
  CarouselItem,
  CarouselNext,
  CarouselPrevious,
} from '~/components/Carousel';
import { Dialog, DialogClose, DialogOverlay, DialogPortal } from '~/components/Dialog';
import { Film, X, XIcon } from 'lucide-react';
import { Image } from '~/components/Image';
import { Loader } from '~/components/Loader';
import type { MessageFragment } from '~/generated/club.server';
import { cn } from '~/utils/cn';
import type { ConnectionResultToNodeArray } from '~/utils/connection-result-to-node-array';
import { Video } from './Video';

export function Medias({
  pictures = [],
  videos = [],
  onDelete,
  className,
}: {
  pictures: MessageFragment['pictures'];
  videos: ConnectionResultToNodeArray<MessageFragment['videos']>;
  onDelete?: (id: string) => void;
  className?: string;
}) {
  const [selectedMedia, setSelectedMedia] = React.useState<string | null>(null);

  if (!videos?.length && !pictures?.length) return null;

  const medias = [...videos, ...(pictures || [])];
  const selectedMediaIndex = medias.findIndex((media) => media.id === selectedMedia);

  return (
    <>
      <div
        className={cn('flex items-center gap-3 overflow-x-auto px-1 py-2', className)}
        onClick={(e) => {
          e.stopPropagation();
          e.preventDefault();
        }}
      >
        {videos?.map((video) => (
          <Media
            alt={video.title || video.id}
            key={video.id}
            src={video.url}
            poster={video.thumbnailUrl}
            type="video"
            onDelete={onDelete ? () => onDelete(video.id) : undefined}
            onClick={() => setSelectedMedia(video.id)}
          />
        ))}

        {pictures?.map((picture) => (
          <Media
            alt={picture.id}
            key={picture.id}
            src={picture.url}
            type="picture"
            onDelete={onDelete ? () => onDelete(picture.id) : undefined}
            onClick={() => setSelectedMedia(picture.id)}
          />
        ))}
      </div>
      <Dialog open={selectedMedia != null} onOpenChange={(e) => !e && setSelectedMedia(null)}>
        <MediasCarousel
          medias={medias}
          startIndex={selectedMediaIndex >= 0 ? selectedMediaIndex : 0}
        />
      </Dialog>
    </>
  );
}

function Media({
  src,
  alt,
  type,
  onDelete,
  onClick,
  poster,
}: {
  src: string;
  alt: string;
  type: 'video' | 'picture';
  poster?: string | null;
  onDelete?: () => void;
  onClick?: () => void;
}) {
  const isLoading = src.startsWith('blob:') || src.startsWith('data:');

  return (
    <div className="group relative flex aspect-square items-center justify-center">
      {isLoading ? (
        <div className="absolute inset-0 z-10 flex items-center justify-center rounded-md bg-foreground/5 backdrop-blur-sm">
          <Loader className="text-background" />
        </div>
      ) : null}

      {onDelete ? (
        <button
          type="button"
          className="appearance-none absolute -right-1.5 -top-1.5 z-20 hidden place-content-center place-items-center rounded-full border-2 border-white bg-red-500 p-px transition-transform ease-in-out hover:scale-125 group-hover:grid"
          onClick={(e) => {
            e.stopPropagation();
            onDelete?.();
          }}
        >
          <XIcon className="size-3.5 text-white" />
        </button>
      ) : null}

      <div
        className="cursor-pointer rounded-md ring-2 ring-transparent ring-offset-2 transition-all duration-300 ease-in-out hover:ring-foreground"
        onClick={(e) => {
          e.stopPropagation();
          onClick?.();
        }}
      >
        {type === 'picture' ? (
          <Image
            alt={alt}
            src={src}
            height={112}
            width={112}
            className="aspect-square size-32 min-w-32 cursor-pointer overflow-hidden rounded-md object-cover object-center ring-2 ring-transparent ring-offset-2 transition-all duration-300 ease-in-out hover:ring-foreground"
            onClick={(e) => {
              e.stopPropagation();
              onClick?.();
            }}
          />
        ) : poster ? (
          <Image
            alt={alt}
            src={poster}
            height={112}
            width={112}
            className="aspect-square size-32 min-w-32 overflow-hidden rounded-md bg-black object-contain object-center"
          />
        ) : (
          <React.Suspense>
            <Video
              src={src}
              poster={poster || undefined}
              className="aspect-square size-32 min-w-32 overflow-hidden rounded-md"
              placeholder={
                poster ||
                'data:image/webp;base64,UklGRloAAABXRUJQVlA4IE4AAAAQAgCdASoQAAkAAQAcJZACdAEPDD5+AZ7AAP79ldqFJOMCUQ2f4oZyhYI+VnKCF7I+peP3z/eiEYipN3I5DkzPm44i9bs36WBd2/HwAAA='
              }
            />
          </React.Suspense>
        )}
      </div>

      {type === 'video' ? (
        <span className="absolute bottom-1 right-1 rounded-sm bg-foreground/30 p-1 text-background backdrop-blur-md">
          <Film className="size-4" />
        </span>
      ) : null}
    </div>
  );
}

function MediasCarousel({
  medias,
  startIndex,
}: {
  startIndex: number;
  medias: (
    | {
        url: string;
        id: string;
      }
    | {
        id: string;
        title: string;
        thumbnailUrl: string;
        url: string;
      }
  )[];
}) {
  return (
    <DialogPortal>
      <DialogOverlay className="bg-black" />
      <DialogPrimitive.Content
        className="fixed z-50 flex h-screen w-screen items-center justify-center overflow-hidden"
        onClick={(e) => {
          e.preventDefault();
          e.stopPropagation();
        }}
      >
        <div className="relative flex h-4/5 w-full items-center justify-center overflow-hidden">
          <Carousel
            className="size-full max-w-full [&_>div]:flex [&_>div]:size-full"
            opts={{ startIndex }}
          >
            <CarouselContent className="w-full">
              {medias.map((media) => (
                <CarouselItem key={media.id} className="flex size-full items-center justify-center">
                  {'thumbnailUrl' in media ? (
                    <React.Suspense fallback={null}>
                      <Video
                        className="aspect-video h-full w-auto object-contain"
                        placeholder={
                          media.thumbnailUrl ||
                          'data:image/webp;base64,UklGRloAAABXRUJQVlA4IE4AAAAQAgCdASoQAAkAAQAcJZACdAEPDD5+AZ7AAP79ldqFJOMCUQ2f4oZyhYI+VnKCF7I+peP3z/eiEYipN3I5DkzPm44i9bs36WBd2/HwAAA='
                        }
                        poster={media.thumbnailUrl}
                        preload="none"
                        loading="viewport"
                        streamType="on-demand"
                        src={media.url}
                        title={media.title}
                      />
                    </React.Suspense>
                  ) : (
                    <img alt={media.id} className="h-full w-auto object-contain" src={media.url} />
                  )}
                </CarouselItem>
              ))}
            </CarouselContent>
            <CarouselPrevious className="left-4" />
            <CarouselNext className="right-4" />
          </Carousel>
        </div>
        <DialogClose className="absolute right-2 top-2 flex size-8 items-center justify-center data-[state=open]:bg-red-500 data-[state=open]:text-white">
          <X className="size-6 text-white" />
          <span className="sr-only w-auto">Close</span>
        </DialogClose>
      </DialogPrimitive.Content>
    </DialogPortal>
  );
}
