import React from 'react';
import TextareaAutosize from 'react-textarea-autosize';
import { validate as validateUuid } from 'uuid';
import { useFetcher } from '@remix-run/react';
import { Avatar } from '~/components/Avatar';
import { Button, buttonVariants } from '~/components/Button';
import { BookPlus, ImagePlusIcon, XIcon } from 'lucide-react';
import type { MessageFragment } from '~/generated/club.server';
import type { UserSession } from '~/types';
import { cn } from '~/utils/cn';
import { connectionResultToNodeArray } from '~/utils/connection-result-to-node-array';
import { Medias } from './Medias';
import { useMedias } from './use-medias';
import type { Video } from './VideoPicker';
import { VideoPicker } from './VideoPicker';

interface Props extends React.ComponentPropsWithRef<'div'> {
  user: UserSession;
  placeholder?: string;
  message?: MessageFragment;
  threadId: string;
  onSubmit?: () => void;
  onCancel?: () => void;
  newMessage?: boolean;
  parentMessageId?: string;
}

export function MessageInput({
  user,
  placeholder,
  message: initialMessage,
  threadId,
  onSubmit,
  newMessage,
  onCancel,
  parentMessageId,
  ...props
}: Props) {
  const message = { ...initialMessage };
  const formRef = React.useRef<HTMLFormElement>(null);
  const action = parentMessageId
    ? '/discussions/threads?index&/createThread'
    : `/discussions/threads/${threadId}?index&/${initialMessage ? 'updateMessage' : 'sendMessage'}`;
  const randomId = React.useId();
  const id = message?.id || randomId;
  const {
    toDeleteMediaIds,
    pictureUploader,
    videoUploader,
    isUploading,
    handleUpload,
    handleDelete,
  } = useMedias(id);
  const fetcher = useFetcher<{ message: string }>();
  const [isOpen, setOpen] = React.useState(!!message.body);
  const [video, setVideo] = React.useState<Video | null>(null);

  const isSubmitting = fetcher.state === 'submitting';

  if (pictureUploader?.uppy) {
    message.pictures = (message.pictures || []).concat(
      pictureUploader?.uppy?.getFiles().map((file) => ({
        id: file.id,
        url: file.meta.ssl_url || URL.createObjectURL(file.data),
      })),
    );
  }

  let videos = connectionResultToNodeArray(message.videos);
  if (videoUploader?.uppy) {
    videos = (videos || []).concat(
      videoUploader?.uppy?.getFiles().map((file) => {
        return {
          id: file.id,
          thumbnailUrl: '',
          title: '',
          url: file.meta.ssl_url || URL.createObjectURL(file.data),
        };
      }),
    );
  }

  if (video) {
    videos = [
      ...videos,
      {
        id: video.id,
        thumbnailUrl: video.thumbnailUrl || '',
        title: video.title || '',
        url: video.url,
      },
    ];
  }

  function handleSubmit(form: HTMLFormElement) {
    const formData = new FormData(form);
    const body = String(formData.get('body'));

    const pictures = (message.pictures || []).map((p) => ({
      id: validateUuid(p.id) ? p.id : undefined,
      url: validateUuid(p.id) ? undefined : p.url,
      delete: toDeleteMediaIds.includes(p.id),
    }));

    const _videos = (videos || []).map((p) => {
      const isUppyFile = !validateUuid(p.id);

      let id = p.id;
      let assemblyId: string | undefined = undefined;
      if (isUppyFile) {
        const file = videoUploader.uppy.getFile(p.id);
        id = file.meta.id;
        assemblyId = videoUploader.uppy.getFile(p.id).transloadit?.assembly;
      }

      return {
        id,
        url: isUppyFile ? p.url : undefined,
        delete: toDeleteMediaIds.includes(p.id),
        assemblyId,
      };
    });

    fetcher.submit(
      {
        body,
        pictures,
        videos: _videos,
        // @ts-expect-error json
        id: initialMessage?.id || undefined,
        parentMessageId,
        type: 'BASE',
        private: false,
      },
      { method: 'POST', action, encType: 'application/json' },
    );

    // Used to refresh the message list
    setTimeout(() => onSubmit?.(), 400);
  }

  return (
    <div {...props} className={cn(props.className)} onClick={(e) => e.stopPropagation()}>
      <form
        ref={formRef}
        className="relative grid min-h-12 grid-cols-[40px_1fr] items-center gap-x-4 overflow-hidden rounded-3xl border ring-2 ring-transparent ring-offset-2 transition-all duration-700 ease-in-out focus-within:ring-ring"
        onSubmit={(e) => {
          e.preventDefault();
          handleSubmit(e.currentTarget);
        }}
      >
        <div className="flex h-12 items-center self-start">
          <Avatar
            // @ts-expect-error avatar
            user={message?.sender || user}
            size="md"
            className="ml-1"
            disableRing
          />
        </div>

        <TextareaAutosize
          onFocus={() => setOpen(true)}
          onBlur={initialMessage ? undefined : () => setOpen(false)}
          className={cn(
            'relative resize-none bg-transparent pr-1 text-foreground outline-none transition-all placeholder:italic',
            { 'z-10': isOpen },
          )}
          placeholder={placeholder}
          defaultValue={message?.body || undefined}
          onKeyDown={(e) => {
            e.stopPropagation();
            if (e.key === 'Enter' && !e.shiftKey) {
              e.preventDefault();
              e.currentTarget.blur();

              const form = e.currentTarget.form;
              form?.requestSubmit();
            }
          }}
          name="body"
          required
        />

        <div
          className={cn('col-span-3 h-0 transition-all duration-700 ease-in-out', {
            'h-11': isOpen,
          })}
        />

        <div
          className={cn(
            'absolute inset-y-0 right-0 flex items-end justify-end gap-1.5 py-[4.5px] pr-1 transition-all',
          )}
        >
          <label
            className={cn(
              buttonVariants({
                size: 'icon',
                variant: 'outline',
              }),
              'cursor-pointer rounded-full',
            )}
            htmlFor={'msg-' + id}
          >
            <ImagePlusIcon className="size-4" />
          </label>
          <input
            type="file"
            multiple
            id={'msg-' + id}
            className="sr-only w-auto"
            accept="image/*, video/*"
            onChange={handleUpload}
          />

          <VideoPicker onChange={setVideo}>
            <Button variant="outline" className="rounded-full" size="icon" type="button">
              <BookPlus className="size-4" />
            </Button>
          </VideoPicker>

          {onCancel ? (
            <Button
              variant="secondary"
              size="icon"
              className="rounded-full"
              disabled={isUploading || isSubmitting}
              onClick={(e) => {
                e.stopPropagation();
                onCancel();
              }}
            >
              <XIcon className="size-4" />
            </Button>
          ) : null}

          <Button
            className="rounded-full transition-all duration-300 ease-in-out hover:scale-110"
            loading={isSubmitting}
            disabled={isUploading}
          >
            {initialMessage ? 'Save' : newMessage ? 'Send' : 'Reply'}
          </Button>
        </div>
      </form>

      <Medias
        pictures={message.pictures?.filter((p) => !toDeleteMediaIds.includes(p.id))}
        videos={videos?.filter((p) => !toDeleteMediaIds.includes(p.id))}
        onDelete={(id) => {
          if (id === video?.id) setVideo(null);
          else handleDelete(id);
        }}
      />
    </div>
  );
}
