import React from 'react';
import { useFetcher } from '@remix-run/react';
import {
  Command,
  CommandEmpty,
  CommandGroup,
  CommandInput,
  CommandItem,
  CommandList,
  CommandLoading,
} from '~/components/Command';
import { ImageOff } from 'lucide-react';
import { Image } from '~/components/Image';
import { Loader } from '~/components/Loader';
import { Popover, PopoverContent, PopoverTrigger } from '~/components/Popover';
import { cn } from '~/utils/cn';
import type { loader as videosLoader } from '../api.videos';

export type Video = Awaited<ReturnType<typeof videosLoader>>['videos'][number];

export function VideoPicker({
  children,
  onChange,
}: {
  children: React.ReactNode;
  onChange: (a: Video) => void;
}) {
  const [open, setOpen] = React.useState(false);

  return (
    <Popover open={open} onOpenChange={setOpen}>
      <PopoverTrigger asChild>{children}</PopoverTrigger>
      <PopoverContent className="my-4 p-0">
        <Contents
          onChange={(video) => {
            onChange(video);
            setOpen(false);
          }}
        />
      </PopoverContent>
    </Popover>
  );
}

function Contents({ onChange }: { onChange: (a: Video) => void }) {
  const [search, setSearch] = React.useState('');
  const fetcher = useFetcher<Awaited<ReturnType<typeof videosLoader>>>();

  React.useEffect(() => {
    if (!fetcher.data && fetcher.state !== 'loading') fetcher.load('/api/videos');
  }, [fetcher]);

  const videos = fetcher.data?.videos;

  return (
    <Command shouldFilter={false} className="relative">
      <CommandInput
        placeholder="Search..."
        value={search}
        onValueChange={(s) => {
          setSearch(s);
          if (s.length > 2) {
            fetcher.load(`/api/videos?filter[title]=${s}`);
          }
        }}
      />
      {fetcher.state === 'loading' ? (
        <CommandLoading>
          <span className="absolute right-0 top-0 z-10 grid size-11 place-content-center place-items-center bg-popover">
            <Loader />
          </span>
        </CommandLoading>
      ) : null}
      <CommandList>
        <CommandEmpty>No results found.</CommandEmpty>
        <CommandGroup>
          {videos?.map((video) => (
            <CommandItem key={video.id} onSelect={() => onChange(video)} value={video.id}>
              <div className="flex items-center gap-4">
                {video.thumbnailUrl ? (
                  <Image
                    src={video.thumbnailUrl}
                    alt={video.title || ''}
                    className="aspect-video h-9 rounded-sm object-cover"
                  />
                ) : (
                  <ImageOff className="aspect-video h-9 rounded-sm text-muted-foreground" />
                )}
                <span className={cn({ 'italic text-muted-foreground': !video.title })}>
                  {video.title || 'No video title'}
                </span>
              </div>
            </CommandItem>
          ))}
        </CommandGroup>
      </CommandList>
    </Command>
  );
}
