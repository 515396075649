import React from 'react';
import dayjs from 'dayjs';
import Linkify from 'linkify-react';
import type { IntermediateRepresentation } from 'linkifyjs';
import plur from 'plur';
import useSWRInfinite from 'swr/infinite';
import { useCopyToClipboard } from 'usehooks-ts';
import { Link, PrefetchPageLinks, useFetcher, useLocation, useNavigate } from '@remix-run/react';
import { A } from '~/components/A';
import {
  AlertDialog,
  AlertDialogContent,
  AlertDialogDescription,
  AlertDialogFooter,
  AlertDialogHeader,
  AlertDialogTitle,
} from '~/components/AlertDialog';
import { Avatar } from '~/components/Avatar';
import { Badge } from '~/components/Badge';
import { Button } from '~/components/Button';
import { Calendar } from '~/components/calendar-picker';
import {
  Card,
  CardContent,
  CardDescription,
  CardFooter,
  CardHeader,
  CardTitle,
} from '~/components/Card';
import {
  Dialog,
  DialogContent,
  DialogDescription,
  DialogHeader,
  DialogTitle,
} from '~/components/Dialog';
import {
  DropdownMenu,
  DropdownMenuContent,
  DropdownMenuItem,
  DropdownMenuLabel,
  DropdownMenuPortal,
  DropdownMenuSeparator,
  DropdownMenuSub,
  DropdownMenuSubContent,
  DropdownMenuSubTrigger,
  DropdownMenuTrigger,
  DropdownMenuRadioGroup,
  DropdownMenuRadioItem,
} from '~/components/Dropdown';
import { Input } from '~/components/form';
import {
  Archive,
  BanIcon,
  CalendarCheck,
  CalendarClock,
  CalendarX,
  CheckCircle2,
  Copy,
  CopyCheck,
  Eye,
  EyeOff,
  FileText,
  Film,
  Heart,
  Info,
  LockIcon,
  MoreHorizontal,
  Pen,
  Rocket,
  Trash,
  Users,
  Users2,
  XCircle,
} from 'lucide-react';
import { Image } from '~/components/Image';
import { Kbd } from '~/components/Kbd';
import { Tooltip, TooltipContent, TooltipProvider, TooltipTrigger } from '~/components/Tooltip';
import type { MessageFragment } from '~/generated/club.server';
import type { Thread, UserSession } from '~/types';
import { getAreaLabel } from '~/utils/area';
import { cn } from '~/utils/cn';
import { connectionResultToNodeArray } from '~/utils/connection-result-to-node-array';
import { formatDate } from '~/utils/date';
import { formatPercent } from '~/utils/price';
import { formatUser } from '~/utils/user';
import { UsersHoverCard } from './UsersHoverCard';
import { getThreadTypeLabel } from './utils';
import { getTextHelperFromStatus, getVariantFromStatus } from '../_c.activities._index/utils';
import { Medias } from '../_c.discussions.threads.$threadId._index/Medias';
import { MessageInput } from '../_c.discussions.threads.$threadId._index/MessageInput';
import type { action } from '../_c.discussions.threads.$threadId._index/route';
import type { loader } from '../_c.discussions.threads.$threadId._index/route';

export const renderLink = ({ attributes, content }: IntermediateRepresentation) => {
  const { href, ...props } = attributes;
  return (
    <A
      to={href}
      {...props}
      className={cn(props.className, 'break-all underline underline-offset-2')}
    >
      {content}
    </A>
  );
};

export const LINKIFY_OPTIONS = {
  nl2br: true,
  render: renderLink,
  target: { url: '_blank' },
};

export const MessageContainer = (props: React.ComponentPropsWithRef<'article'>) => (
  <article
    {...props}
    className={cn('col-span-1 row-span-3 grid grid-cols-[80px_1fr]', props.className)}
    suppressHydrationWarning
  />
);

export const MessageSenderAvatar = ({
  message,
  hideLine,
}: {
  message: Pick<MessageFragment, 'sender'>;
  hideLine?: boolean;
}) => {
  return (
    <div className="col-span-1 row-span-5 flex flex-col items-center gap-2 pt-6">
      <div>
        <Avatar user={message.sender} size="md" />
      </div>

      {hideLine ? null : <div className="mx-auto h-full w-1 bg-muted" />}
    </div>
  );
};

export const BOOST_WORDING = {
  1: 'Boosted threads (1-5) stay atop. Max is 5. Currently 1.',
  2: 'Boosted threads (1-5) stay atop. Max is 5. Currently 2.',
  3: 'Boosted threads (1-5) stay atop. Max is 5. Currently 3.',
  4: 'Boosted threads (1-5) stay atop. Max is 5. Currently 4.',
  5: 'Boosted threads (1-5) stay atop. Max is 5. Currently 5.',
};

export const MessageHeader = ({
  message,
  thread,
  onLike,
  onRedact,
  onDelete,
  onEdit,
  onLock,
  onArchive,
  onAcceptInCommunity,
  onRejectFromCommunity,
  onBan,
  threadId,
}: {
  message: MessageFragment;
  thread?: Thread;
  onLike: (message: MessageFragment) => void;
  onRedact: (message: MessageFragment) => void;
  onDelete: (message: MessageFragment) => void;
  onEdit: (message: MessageFragment) => void;
  onLock?: () => void;
  onArchive?: () => void;
  onAcceptInCommunity?: () => void;
  onRejectFromCommunity?: () => void;
  onBan?: () => void;
  threadId: string;
}) => {
  const [copiedLink, copyLink] = useCopyToClipboard();
  const fetcher = useFetcher<typeof action>();
  const [open, setOpen] = React.useState(false);
  const [publishAt, setPublishAt] = React.useState<Date | null | undefined>(
    thread?.publishAt ? dayjs(thread?.publishAt).toDate() : null,
  );
  const RedactIcon = message.redactedAt ? EyeOff : Eye;

  const handleClick =
    (handler?: (e: React.SyntheticEvent) => void) => (e: React.SyntheticEvent) => {
      e.stopPropagation();

      handler?.(e);
    };

  let boost = thread?.boost;
  if (fetcher.json != null && typeof fetcher.json === 'object' && 'boost' in fetcher.json) {
    boost = fetcher.json.boost as number;
  } else if (fetcher.data != null && 'thread' in fetcher.data && 'boost' in fetcher.data.thread) {
    boost = fetcher.data.thread.boost;
  }

  let optimisticPublishAt = publishAt;
  if (fetcher.json != null && typeof fetcher.json === 'object' && 'publishAt' in fetcher.json) {
    optimisticPublishAt = dayjs(fetcher.json.publishAt as any).toDate();
  } else if (
    fetcher.data != null &&
    'thread' in fetcher.data &&
    'publishAt' in fetcher.data.thread
  ) {
    optimisticPublishAt = dayjs(fetcher.data.thread.publishAt).toDate();
  }

  const _date = dayjs().toDate();

  return (
    <>
      <CardHeader className="flex flex-row items-start justify-between space-y-0 pl-0">
        <div className="flex h-full flex-col place-content-center">
          <CardTitle className="flex items-center gap-2">
            <A
              to={`/users/${message.sender.id}`}
              className={cn('text-lg font-semibold leading-none tracking-tight', {
                'text-red-500 line-through': message.sender.bannedAt != null,
              })}
            >
              {formatUser(message.sender)}
            </A>
            <span className="text-sm font-normal text-muted-foreground">&#x2022;</span>
            <TooltipProvider>
              <Tooltip>
                <TooltipTrigger className="text-xs font-normal text-muted-foreground">
                  {dayjs().to(message.insertedAt)}
                </TooltipTrigger>
                <TooltipContent>
                  {formatDate(message.insertedAt, 'YYYY-MM-DD HH:mm:ss')}
                </TooltipContent>
              </Tooltip>
            </TooltipProvider>
          </CardTitle>
          {thread ? (
            <CardDescription>
              {getThreadTypeLabel(thread.type)}
              {!['SYSTEM', 'PRIVATE'].includes(thread.type) ? (
                <>
                  <span className="mx-2 inline-flex items-center gap-2 text-sm font-normal text-muted-foreground">
                    &#x2022;
                  </span>
                  {thread?.areas?.length ? (
                    <>
                      {getAreaLabel(thread?.areas[0], true)}
                      {thread?.areas?.length - 1 > 0 ? (
                        <TooltipProvider>
                          <Tooltip>
                            <TooltipTrigger asChild>
                              <Badge variant="gray" className="ml-1 px-2 text-muted-foreground">
                                +{thread?.areas?.length - 1}
                              </Badge>
                            </TooltipTrigger>
                            <TooltipContent className="max-w-md">
                              {thread?.areas.map((area) => getAreaLabel(area, true)).join('  ·  ')}
                            </TooltipContent>
                          </Tooltip>
                        </TooltipProvider>
                      ) : null}
                    </>
                  ) : (
                    'All areas'
                  )}
                </>
              ) : null}
            </CardDescription>
          ) : null}
        </div>

        <span className="flex items-center gap-4">
          {thread?.publishAt && !thread.published ? (
            <TooltipProvider>
              <Tooltip>
                <TooltipTrigger asChild>
                  <span className="flex aspect-square size-6 items-center justify-center rounded-full border p-px text-xs">
                    📆
                  </span>
                </TooltipTrigger>
                <TooltipContent>
                  It will be published on {formatDate(thread.publishAt, 'MMM D, YYYY [at] HH:mm')}
                </TooltipContent>
              </Tooltip>
            </TooltipProvider>
          ) : null}

          {thread?.conditionFlags.includes('COMMUNITYACCEPTED') ? (
            <TooltipProvider>
              <Tooltip>
                <TooltipTrigger asChild>
                  <span className="flex aspect-square size-6 items-center justify-center rounded-full border p-px text-xs">
                    ✅
                  </span>
                </TooltipTrigger>
                <TooltipContent>
                  This thread is visible only to approved community members.
                </TooltipContent>
              </Tooltip>
            </TooltipProvider>
          ) : null}

          {boost ? (
            <TooltipProvider>
              <Tooltip>
                <TooltipTrigger asChild>
                  <span className="flex aspect-square size-6 items-center justify-center rounded-full border p-px text-xs">
                    🚀
                  </span>
                </TooltipTrigger>
                <TooltipContent>
                  {BOOST_WORDING[boost as keyof typeof BOOST_WORDING]}
                </TooltipContent>
              </Tooltip>
            </TooltipProvider>
          ) : null}

          {thread?.lockedAt ? (
            <TooltipProvider>
              <Tooltip>
                <TooltipTrigger asChild>
                  <Badge variant="gray">locked</Badge>
                </TooltipTrigger>
                <TooltipContent>
                  Users are no longer able to respond, but they retain the ability to view the
                  thread. Locked on {formatDate(thread.lockedAt, 'YYYY-MM-DD HH:mm:ss')}
                </TooltipContent>
              </Tooltip>
            </TooltipProvider>
          ) : null}

          {thread?.archivedAt ? (
            <TooltipProvider>
              <Tooltip>
                <TooltipTrigger asChild>
                  <Badge variant="gray">archived</Badge>
                </TooltipTrigger>
                <TooltipContent>
                  Users are unable to both see and respond to archived threads. Archived on &nbsp;
                  {formatDate(thread.archivedAt, 'YYYY-MM-DD HH:mm:ss')}
                </TooltipContent>
              </Tooltip>
            </TooltipProvider>
          ) : null}

          <DropdownMenu>
            <DropdownMenuTrigger asChild>
              <Button size="icon" variant="ghost">
                <MoreHorizontal className="size-4" />
              </Button>
            </DropdownMenuTrigger>
            <DropdownMenuContent>
              <DropdownMenuLabel>Message</DropdownMenuLabel>
              <DropdownMenuItem onClick={handleClick(() => onLike(message))}>
                <Heart
                  className={cn('mr-2 h-4 w-4', {
                    'fill-black': message.liked,
                  })}
                />{' '}
                {message.liked ? 'Unlike' : 'Like'}
              </DropdownMenuItem>
              <DropdownMenuItem onClick={handleClick(() => setOpen(true))}>
                <Info className="mr-2 size-4" />
                Info
              </DropdownMenuItem>
              <DropdownMenuItem
                onClick={handleClick((e) => {
                  copyLink(location.origin + `/discussions/threads/${threadId}#${message.id}`);
                  e.stopPropagation();
                })}
              >
                {copiedLink ? (
                  <CopyCheck className="mr-2 size-4" />
                ) : (
                  <Copy className="mr-2 size-4" />
                )}
                Copy Link
              </DropdownMenuItem>
              <DropdownMenuItem onClick={handleClick(() => onEdit(message))}>
                <Pen className="mr-2 size-4" />
                Edit
              </DropdownMenuItem>
              <DropdownMenuItem onClick={handleClick(() => onRedact(message))}>
                <RedactIcon className="mr-2 size-4" /> {message.redactedAt ? 'Unredact' : 'Redact'}
                <TooltipProvider>
                  <Tooltip>
                    <TooltipTrigger asChild>
                      <Info className="ml-auto size-3 text-muted-foreground" />
                    </TooltipTrigger>
                    <TooltipContent>
                      This message will be hidden from users. This action is reversible.
                    </TooltipContent>
                  </Tooltip>
                </TooltipProvider>
              </DropdownMenuItem>
              <DropdownMenuItem
                className="text-red-500"
                onClick={handleClick(() => onDelete(message))}
                disabled={message.deletedAt != null}
              >
                <Trash className="mr-2 size-4" />
                Delete
              </DropdownMenuItem>
              <DropdownMenuSeparator />
              <DropdownMenuLabel>User</DropdownMenuLabel>

              {onAcceptInCommunity && onRejectFromCommunity ? (
                <DropdownMenuSub>
                  <DropdownMenuSubTrigger>
                    <Users2 className="mr-2 size-4" />
                    Community
                  </DropdownMenuSubTrigger>
                  <DropdownMenuPortal>
                    <DropdownMenuSubContent>
                      <DropdownMenuItem
                        disabled={message.sender.communityVettingStatus === 'ACCEPTED'}
                        className="text-green-500"
                        onClick={handleClick(() => onAcceptInCommunity())}
                      >
                        <CheckCircle2 className="mr-2 size-4" />
                        Accept
                      </DropdownMenuItem>
                      <DropdownMenuItem
                        disabled={message.sender.communityVettingStatus === 'REJECTED'}
                        className="text-red-500"
                        onClick={handleClick(() => onRejectFromCommunity())}
                      >
                        <XCircle className="mr-2 size-4" />
                        Reject
                      </DropdownMenuItem>
                    </DropdownMenuSubContent>
                  </DropdownMenuPortal>
                </DropdownMenuSub>
              ) : null}
              {onBan ? (
                <DropdownMenuItem onClick={handleClick(() => onBan())} className="text-red-500">
                  <BanIcon className="mr-2 size-4" /> {message.sender.bannedAt ? 'Unban' : 'Ban'}
                </DropdownMenuItem>
              ) : null}
              {thread ? (
                <>
                  <DropdownMenuSeparator />
                  <DropdownMenuLabel>Thread</DropdownMenuLabel>

                  <DropdownMenuSub>
                    <DropdownMenuSubTrigger onClick={(e) => e.stopPropagation()}>
                      <Rocket className="mr-2 size-4" /> Boost
                    </DropdownMenuSubTrigger>
                    <DropdownMenuSubContent>
                      <DropdownMenuRadioGroup
                        value={'' + (boost || 0)}
                        onValueChange={(v) =>
                          fetcher.submit(
                            { boost: Number.parseInt(v, 10) },
                            {
                              method: 'POST',
                              action: `/discussions/threads/${thread.id}/?index&/updateThread`,
                              encType: 'application/json',
                            },
                          )
                        }
                      >
                        <DropdownMenuRadioItem value="0" onClick={handleClick()}>
                          0 <small className="ml-2 text-muted-foreground">(No Boost)</small>
                        </DropdownMenuRadioItem>
                        <DropdownMenuRadioItem value="1" onClick={handleClick()}>
                          1 <small className="ml-2 text-muted-foreground">(Low)</small>
                        </DropdownMenuRadioItem>
                        <DropdownMenuRadioItem value="2" onClick={handleClick()}>
                          2 <small className="ml-2 text-muted-foreground">(Moderate)</small>
                        </DropdownMenuRadioItem>
                        <DropdownMenuRadioItem value="3" onClick={handleClick()}>
                          3 <small className="ml-2 text-muted-foreground">(Medium)</small>
                        </DropdownMenuRadioItem>
                        <DropdownMenuRadioItem value="4" onClick={handleClick()}>
                          4 <small className="ml-2 text-muted-foreground">(High)</small>
                        </DropdownMenuRadioItem>
                        <DropdownMenuRadioItem value="5" onClick={handleClick()}>
                          5 <small className="ml-2 text-muted-foreground">(Maximum)</small>
                        </DropdownMenuRadioItem>
                      </DropdownMenuRadioGroup>
                    </DropdownMenuSubContent>
                  </DropdownMenuSub>
                  <DropdownMenuSub>
                    <DropdownMenuSubTrigger>
                      <CalendarClock className="mr-2 size-4" /> Schedule
                    </DropdownMenuSubTrigger>
                    <DropdownMenuSubContent>
                      <Calendar
                        selected={optimisticPublishAt ?? _date}
                        onSelect={(d) => setPublishAt(d)}
                        mode="single"
                        footer={
                          <div className="mt-4 flex items-center gap-2">
                            <Input
                              type="time"
                              value={dayjs(optimisticPublishAt || _date).format('HH:mm')}
                              className="pr-3"
                              required
                              onChange={(e) => {
                                e.stopPropagation();
                                const [h, m] = e.target.value.split(':');

                                let _d = dayjs(optimisticPublishAt || new Date());
                                _d = _d.hour(Number.parseInt(h, 10));
                                _d = _d.minute(Number.parseInt(m, 10));

                                setPublishAt(_d.toDate());
                              }}
                            />

                            <Button
                              type="button"
                              variant="ghost"
                              className="ml-auto"
                              onClick={handleClick(() => setPublishAt(null))}
                            >
                              Reset
                            </Button>
                            <Button
                              type="button"
                              variant="default"
                              className="ml-auto"
                              loading={fetcher.state === 'submitting'}
                              onClick={handleClick(() =>
                                fetcher.submit(
                                  {
                                    publishAt: publishAt
                                      ? formatDate(publishAt, 'YYYY-MM-DDTHH:mm:ssZ')
                                      : null,
                                  },
                                  {
                                    method: 'POST',
                                    action: `/discussions/threads/${thread.id}/?/updateThread`,
                                    encType: 'application/json',
                                  },
                                ),
                              )}
                            >
                              Save
                            </Button>
                          </div>
                        }
                      />
                    </DropdownMenuSubContent>
                  </DropdownMenuSub>
                  <DropdownMenuItem asChild>
                    <Link
                      reloadDocument
                      className="flex items-center"
                      to={`/api/thread/${threadId}/participants.csv`}
                    >
                      <FileText className="mr-2 size-4" />
                      Export Participants <Kbd className="ml-4 self-end">csv</Kbd>
                    </Link>
                  </DropdownMenuItem>
                  <DropdownMenuItem onClick={handleClick(() => onLock?.())}>
                    <LockIcon className="mr-2 size-4" /> {thread.lockedAt ? 'Unlock' : 'Lock'}
                  </DropdownMenuItem>
                  <DropdownMenuItem onClick={handleClick(() => onArchive?.())}>
                    <Archive className="mr-2 size-4" />
                    {thread.archivedAt ? 'Unarchive' : 'Archive'}
                  </DropdownMenuItem>
                </>
              ) : null}
            </DropdownMenuContent>
          </DropdownMenu>
        </span>
      </CardHeader>
      <Dialog open={open} onOpenChange={setOpen}>
        <DialogContent>
          <DialogHeader>
            <DialogTitle>Message Informations</DialogTitle>
          </DialogHeader>
          <DialogDescription asChild>
            <div>
              {[
                {
                  name: 'Created at',
                  value: formatDate(message.insertedAt, 'YYYY-MM-DD HH:mm:ss'),
                },
                { name: 'Like count', value: message.likeCount },
                {
                  name: 'Report count',
                  value: message.reportCount,
                  tooltip: 'Reported by the community',
                },
                {
                  name: 'Redacted at',
                  tooltip: 'This message has been hidden by Colette',
                  value: message.redactedAt
                    ? formatDate(message.redactedAt, 'YYYY-MM-DD HH:mm:ss')
                    : '-',
                },
                {
                  name: 'Moderated at',
                  tooltip: 'This message has been scanned by our moderation system',
                  value: message.moderatedAt
                    ? formatDate(message.moderatedAt, 'YYYY-MM-DD HH:mm:ss')
                    : '-',
                },
                {
                  name: 'Flagged at',
                  tooltip: 'This message has been flagged as inapropriate by our moderation system',
                  value: message.flaggedAt
                    ? formatDate(message.flaggedAt, 'YYYY-MM-DD HH:mm:ss')
                    : '-',
                },
                {
                  name: 'Deleted at',
                  value: message.deletedAt || '-',
                },
              ].map((stat) =>
                stat.tooltip ? (
                  <TooltipProvider key={stat.name}>
                    <Tooltip>
                      <TooltipTrigger asChild>
                        <div
                          className={cn('my-3 flex items-center justify-between text-foreground', {
                            'cursor-help underline decoration-dotted': stat.tooltip != null,
                          })}
                        >
                          <span className="font-medium">{stat.name}</span>
                          <span className="ml-28">{stat.value}</span>
                        </div>
                      </TooltipTrigger>
                      <TooltipContent>{stat.tooltip}</TooltipContent>
                    </Tooltip>
                  </TooltipProvider>
                ) : (
                  <div
                    key={stat.name}
                    className={cn('my-3 flex items-center justify-between text-foreground', {
                      'cursor-help underline decoration-dotted': stat.tooltip != null,
                    })}
                  >
                    <span className="font-medium">{stat.name}</span>
                    <span className="ml-28">{stat.value}</span>
                  </div>
                ),
              )}

              {message.moderationResult ? (
                <>
                  <div className="my-4 h-px w-full bg-muted" />

                  <span className="font-semibold">Moderation result</span>

                  {[
                    {
                      name: 'Hate',
                      value: formatPercent(message.moderationResult.hate * 100),
                    },
                    {
                      name: 'Rude',
                      value: formatPercent(message.moderationResult.rude * 100),
                    },
                    {
                      name: 'Self harm',
                      value: formatPercent(message.moderationResult.selfHarm * 100),
                    },
                    {
                      name: 'Sexual',
                      value: formatPercent(message.moderationResult.sexual * 100),
                    },
                    {
                      name: 'Violence',
                      value: formatPercent(message.moderationResult.violence * 100),
                    },
                  ].map((stat) => (
                    <div
                      key={stat.name}
                      className="my-3 flex items-center justify-between text-foreground"
                    >
                      <span className="font-medium">{stat.name}</span>
                      <span className="ml-28">{stat.value}</span>
                    </div>
                  ))}
                </>
              ) : null}
            </div>
          </DialogDescription>
        </DialogContent>
      </Dialog>
    </>
  );
};

export const MessageContent = ({
  message,
  thread,
}: {
  message: MessageFragment;
  thread?: Thread;
}) => {
  const activity = thread?.activity;
  const badge = activity ? getVariantFromStatus(activity.status) : null;
  const StatusIcon = badge?.icon;

  const section = thread?.mediaSection;

  return (
    <CardContent className="overflow-hidden pl-0">
      <CardDescription className="flex flex-col gap-6">
        <Linkify
          as="span"
          className="break-before-page text-base font-light leading-relaxed text-foreground"
          options={LINKIFY_OPTIONS}
        >
          {message?.body?.trim()}
        </Linkify>

        <Medias pictures={message.pictures} videos={connectionResultToNodeArray(message.videos)} />

        {activity && badge && StatusIcon ? (
          <Link
            to={`/activities/${activity.id}`}
            onClick={(e) => e.stopPropagation()}
            prefetch="intent"
          >
            <Attachment>
              <AttachmentCover
                src={activity.picture?.url || '/placeholder.png'}
                alt={activity.name}
              />
              <AttachmentHeader>
                <AttachmentTitle>{activity.name}</AttachmentTitle>
                <AttachmentDescription>{activity.headline}</AttachmentDescription>
              </AttachmentHeader>
              <AttachmentFooter className="justify-between">
                <div className="flex flex-wrap items-center gap-2 text-sm text-muted-foreground">
                  <AttachmentFooterItem>
                    <Users className="size-3.5" /> {activity.attendeeCount}
                    &nbsp;/
                    {activity.maxAttendees}
                  </AttachmentFooterItem>
                  &#x2022;
                  <AttachmentFooterItem>
                    <CalendarClock className="size-3.5" /> {formatDate(activity.startTime)}
                  </AttachmentFooterItem>
                  &#x2022;
                  <AttachmentFooterItem>
                    {getAreaLabel(activity.areas[0], true)}
                    {activity.areas?.length - 1 > 0 ? (
                      <TooltipProvider>
                        <Tooltip>
                          <TooltipTrigger asChild>
                            <Badge variant="gray" className="text-muted-foreground">
                              +{activity.areas?.length - 1}
                            </Badge>
                          </TooltipTrigger>
                          <TooltipContent className="max-w-md">
                            {activity.areas.map((area) => getAreaLabel(area, true)).join('  ·  ')}
                          </TooltipContent>
                        </Tooltip>
                      </TooltipProvider>
                    ) : null}
                  </AttachmentFooterItem>
                </div>

                <TooltipProvider>
                  <Tooltip>
                    <TooltipTrigger asChild>
                      <Badge variant={badge.color} className="aspect-square size-8 rounded-full">
                        <StatusIcon className="size-6" />
                      </Badge>
                    </TooltipTrigger>
                    <TooltipContent>
                      {getTextHelperFromStatus(activity.status) +
                        (activity.publicationTime
                          ? formatDate(
                              activity.publicationTime,
                              ' [Published on] MMM D, YYYY h:mm A',
                            )
                          : '')}
                    </TooltipContent>
                  </Tooltip>
                </TooltipProvider>
              </AttachmentFooter>
            </Attachment>
          </Link>
        ) : null}

        {section ? (
          <Link
            to={`/library/${section.id}`}
            onClick={(e) => e.stopPropagation()}
            prefetch="intent"
          >
            <Attachment>
              <AttachmentCover
                src={section.thumbnailUrl || '/placeholder.png'}
                alt={section.title}
              />
              <AttachmentHeader>
                <AttachmentTitle>{section.title}</AttachmentTitle>
                <AttachmentDescription>{section.headline}</AttachmentDescription>
              </AttachmentHeader>
              <AttachmentFooter className="justify-between">
                <div className="flex flex-wrap items-center gap-2 text-sm text-muted-foreground">
                  <AttachmentFooterItem>
                    <Film className="size-3.5" /> {section.items?.count || 0}
                  </AttachmentFooterItem>
                  {section.sectionUsers?.length ? (
                    <>
                      &#x2022;
                      <AttachmentFooterItem className="gap-0 -space-x-1 rounded-full border p-0.5 hover:space-x-2">
                        {section.sectionUsers?.map((u) => (
                          <Avatar
                            key={u.user.id}
                            user={u.user}
                            size="xs"
                            className="transition-all"
                            disableRing
                          />
                        ))}
                      </AttachmentFooterItem>
                    </>
                  ) : null}
                </div>
                <TooltipProvider delayDuration={50}>
                  <Tooltip>
                    <TooltipTrigger asChild>
                      <Badge
                        variant={
                          section.status === 'PUBLISHED'
                            ? 'green'
                            : section.status === 'UNPUBLISHED'
                              ? 'orange'
                              : 'auto'
                        }
                        className="aspect-square size-8 rounded-full"
                      >
                        {section.status === 'PUBLISHED' ? (
                          <CalendarCheck className="size-6" />
                        ) : null}
                        {section.status === 'UNPUBLISHED' ? <CalendarX className="size-6" /> : null}
                        {section.status === 'ARCHIVED' ? <Archive className="size-6" /> : null}
                      </Badge>
                    </TooltipTrigger>
                    <TooltipContent>
                      {section.publicationTime
                        ? formatDate(section.publicationTime, ' [Published on] MMM D, YYYY h:mm A')
                        : 'Not published yet'}
                    </TooltipContent>
                  </Tooltip>
                </TooltipProvider>
              </AttachmentFooter>
            </Attachment>
          </Link>
        ) : null}
      </CardDescription>
    </CardContent>
  );
};

function Attachment(props: React.ComponentPropsWithRef<typeof Card>) {
  return <Card {...props} className={cn('w-full overflow-hidden', props.className)} />;
}

function AttachmentCover({ src, alt }: { src: string; alt: string }) {
  return <Image src={src} alt={alt} className="h-48 w-full object-cover" />;
}

const AttachmentHeader = CardHeader;

function AttachmentTitle(props: React.ComponentPropsWithRef<typeof CardTitle>) {
  return <CardTitle {...props} className={cn('line-clamp-2', props.className)} />;
}

function AttachmentDescription(props: React.ComponentPropsWithRef<typeof CardDescription>) {
  return <CardDescription {...props} className={cn('line-clamp-4', props.className)} />;
}

function AttachmentFooter(props: React.ComponentPropsWithRef<typeof CardFooter>) {
  return <CardFooter {...props} className={cn('justify-between', props.className)} />;
}

function AttachmentFooterItem(props: React.ComponentPropsWithRef<'span'>) {
  return <span {...props} className={cn('flex items-center gap-2', props.className)} />;
}

export const MessageFooter = ({
  message,
  thread,
}: {
  message: MessageFragment;
  thread?: Thread;
}) => {
  const repliesCount = thread?.messages?.count
    ? Math.max((thread?.messages?.count || 0) - 1)
    : message.childThread?.messages?.count != null
      ? message.childThread.messages.count
      : null;
  const participants = connectionResultToNodeArray(thread?.participants);
  const likesCount = message.likeCount;
  const reportsCount = message.reportCount || 0;
  const atLeastOneValue = [
    likesCount,
    reportsCount,
    repliesCount,
    thread?.participants?.count,
    message.editedAt,
    message.flaggedAt,
    message.redactedAt,
    message.deletedAt,
  ].some(Boolean);

  if (!atLeastOneValue) return null;

  return (
    <CardFooter className="gap-2 pl-0 text-sm text-muted-foreground">
      {likesCount || atLeastOneValue ? (
        <UsersHoverCard
          open={likesCount ? undefined : false}
          route={`/api/messages/${message.id}/likers`}
        >
          <span className={cn({ 'font-medium text-foreground': message.liked })}>
            {likesCount}&nbsp;
            {plur('like', likesCount)}
          </span>
        </UsersHoverCard>
      ) : null}

      {repliesCount != null ? (
        <>
          &#183;
          <span>
            {repliesCount}&nbsp;
            {plur('reply', repliesCount)}
          </span>
        </>
      ) : null}

      {message.editedAt ? (
        <>
          &#183;
          <TooltipProvider>
            <Tooltip>
              <TooltipTrigger>edited</TooltipTrigger>
              <TooltipContent>{formatDate(message.editedAt, 'YYYY-MM-DD HH:mm:ss')}</TooltipContent>
            </Tooltip>
          </TooltipProvider>
        </>
      ) : null}

      {message.flaggedAt ? (
        <>
          &#183;
          <TooltipProvider>
            <Tooltip>
              <TooltipTrigger className="text-orange-400">flagged</TooltipTrigger>
              <TooltipContent>
                {formatDate(message.flaggedAt, 'YYYY-MM-DD HH:mm:ss')}
              </TooltipContent>
            </Tooltip>
          </TooltipProvider>
        </>
      ) : null}

      {reportsCount ? (
        <>
          &#183;
          <UsersHoverCard route={`/api/messages/${message.id}/reporters`}>
            <span className="text-orange-400">
              {reportsCount}&nbsp;
              {plur('report', reportsCount)}
            </span>
          </UsersHoverCard>
        </>
      ) : null}

      {message.redactedAt ? (
        <>
          &#183;
          <TooltipProvider>
            <Tooltip>
              <TooltipTrigger className="text-orange-400">redacted</TooltipTrigger>
              <TooltipContent>
                {formatDate(message.redactedAt, 'YYYY-MM-DD HH:mm:ss')}
              </TooltipContent>
            </Tooltip>
          </TooltipProvider>
        </>
      ) : null}

      {message.deletedAt ? (
        <>
          &#183;
          <TooltipProvider>
            <Tooltip>
              <TooltipTrigger className="text-red-500">deleted</TooltipTrigger>
              <TooltipContent>
                {formatDate(message.deletedAt, 'YYYY-MM-DD HH:mm:ss')}
              </TooltipContent>
            </Tooltip>
          </TooltipProvider>
        </>
      ) : null}

      {thread?.type === 'PRIVATE' && participants.length === 2 ? (
        <TooltipProvider delayDuration={50}>
          <Tooltip>
            <TooltipTrigger asChild>
              <div className="ml-auto flex items-center gap-4 rounded-full border py-1 pl-1 pr-2">
                <span className="flex max-w-44 items-center gap-1 whitespace-nowrap">
                  <Avatar
                    size="xs"
                    user={
                      participants[0].id === message.sender.id ? participants[1] : participants[0]
                    }
                    disableRing
                  />
                  <span className="truncate">
                    {formatUser(
                      participants[0].id === message.sender.id ? participants[1] : participants[0],
                    )}
                  </span>
                </span>
              </div>
            </TooltipTrigger>
            <TooltipContent>
              Private conversation between{' '}
              <strong className="font-medium">{formatUser(participants[0])}</strong> and{' '}
              <strong className="font-medium">{formatUser(participants[1])}</strong>
            </TooltipContent>
          </Tooltip>
        </TooltipProvider>
      ) : thread?.id && participants?.length ? (
        <div className="ml-auto flex items-center gap-2 px-3 py-1">
          <div className="flex items-center [&>*:first-child]:ml-0 [&>*]:-ml-2 [&>*]:border-2 [&>*]:border-background">
            {[participants[0], participants[1], participants[2]].filter(Boolean).map((p) => (
              <Avatar key={p.id} size="xs" user={p} disableRing />
            ))}
          </div>
          <UsersHoverCard route={`/api/thread/${thread.id}/participants`}>
            {thread?.participants?.count || 0}&nbsp;
            {plur('participant', thread?.participants?.count || 0)}
          </UsersHoverCard>
        </div>
      ) : null}
    </CardFooter>
  );
};

export const MessagePlaceholder = ({
  hideLine,
  className,
}: {
  hideLine?: boolean;
  className?: string;
}) => (
  <MessageContainer className={className}>
    <div className="col-span-1 row-span-full flex flex-col items-center justify-start gap-2 bg-red-300 pt-6">
      <Avatar size="md" className="min-h-[40px]" />

      {hideLine ? null : <div className="mx-auto h-full w-1 bg-muted" />}
    </div>

    <CardHeader className="flex flex-row items-start justify-between pl-0">
      <CardTitle className="flex items-center gap-2">
        <div className="block h-3 w-28 rounded-md bg-muted" />
        <div className="block h-3 w-20 rounded-md bg-muted" />
      </CardTitle>
    </CardHeader>
    <CardContent className="relative pl-0">
      <CardDescription className="flex flex-col gap-3">
        <div className="block h-2 w-full rounded-md bg-muted" />
        <div className="grid grid-cols-5 gap-3">
          <div className="block h-2 w-full rounded-md bg-muted" />
          <div className="block h-2 w-full rounded-md bg-muted" />
          <div className="block h-2 w-full rounded-md bg-muted" />
          <div className="block h-2 w-full rounded-md bg-muted" />
          <div className="block h-2 w-full rounded-md bg-muted" />
        </div>
        <div className="block h-2 w-full rounded-md bg-muted" />
        <div className="grid grid-cols-7 gap-3">
          <div className="block h-2 w-full rounded-md bg-muted" />
          <div className="block h-2 w-full rounded-md bg-muted" />
          <div className="block h-2 w-full rounded-md bg-muted" />
          <div className="block h-2 w-full rounded-md bg-muted" />
          <div className="block h-2 w-full rounded-md bg-muted" />
          <div className="block h-2 w-full rounded-md bg-muted" />
          <div className="block h-2 w-full rounded-md bg-muted" />
        </div>
        <div className="grid grid-cols-2 gap-3">
          <div className="block h-2 w-full rounded-md bg-muted" />
          <div className="block h-2 w-full rounded-md bg-muted" />
        </div>
        <div className="block h-2 w-full rounded-md bg-muted" />
        <div className="grid grid-cols-3 gap-3">
          <div className="block h-2 w-full rounded-md bg-muted" />
          <div className="block h-2 w-full rounded-md bg-muted" />
          <div className="block h-2 w-full rounded-md bg-muted" />
        </div>
        <div className="block h-2 w-full rounded-md bg-muted" />
      </CardDescription>
      <div className="absolute inset-0 grid place-content-center place-items-center">
        <div className="rounded-md bg-red-50 px-4 py-3 text-muted-foreground">
          This message has been deleted
        </div>
      </div>
    </CardContent>
    <CardFooter className="flex items-center gap-2 pl-0">
      <div className="block h-2 w-16 rounded-md bg-muted" />
      <div className="block h-2 w-16 rounded-md bg-muted" />
      <div className="block h-2 w-16 rounded-md bg-muted" />
    </CardFooter>
  </MessageContainer>
);

interface Props {
  message: MessageFragment;
  thread?: Thread;
  className?: string;
  hideLine?: boolean;
  onLike?: (message: MessageFragment, threadId: string) => void;
  onBan?: (message: MessageFragment, threadId: string) => void;
  onRejectFromCommunity?: (message: MessageFragment, threadId: string) => void;
  onAcceptInCommunity?: (message: MessageFragment, threadId: string) => void;
  onRedact?: (message: MessageFragment, threadId: string) => void;
  onDelete?: (message: MessageFragment, threadId: string) => void;
  onEdit?: (message: MessageFragment, threadId: string) => void;
  onLock?: (message: MessageFragment, threadId: string) => void;
  onArchive?: (message: MessageFragment, threadId: string) => void;
  onClick?: (message: MessageFragment, threadId: string) => void;
  threadId: string;
  user: UserSession;
  href?: string;
  replyOnClick?: boolean;
  onReply?: () => void;
}

export const Message = React.memo(
  ({
    message,
    className,
    hideLine,
    thread,
    threadId,
    onLike,
    onDelete,
    onRedact,
    onEdit,
    onLock,
    onArchive,
    onBan,
    onClick,
    onAcceptInCommunity,
    onRejectFromCommunity,
    user,
    href,
    replyOnClick,
    onReply,
  }: Props) => {
    const navigate = useNavigate();
    const fetcher = useFetcher<{ message?: string }>();
    const action = `/discussions/threads/${threadId}`;
    const userAction = `/users/${message.sender.id}`;
    const deleteMessageFetcher = useFetcher();
    const [deleteDialogOpen, setDeleteDialogOpen] = React.useState<boolean>(false);
    const [editing, setEditing] = React.useState<boolean>(false);
    const [hover, setHover] = React.useState<boolean>(false);
    const location = useLocation();
    const isHighlighted = location.hash.replace('#', '') === message.id;
    const [canReply, setCanReply] = React.useState<boolean>(false);

    // biome-ignore lint/correctness/useExhaustiveDependencies: it's okay
    React.useEffect(() => {
      if (deleteMessageFetcher.state === 'loading') setDeleteDialogOpen(false);
    }, [deleteMessageFetcher.state, setDeleteDialogOpen]);

    function handleLike(message: MessageFragment) {
      fetcher.submit(
        { id: message.id },
        {
          method: 'post',
          action: action + (message.liked ? '?index&/unlikeMessage' : '?index&/likeMessage'),
        },
      );

      onLike?.(message, threadId);
    }

    function handleRedact(message: MessageFragment) {
      fetcher.submit(
        { id: message.id },
        {
          method: 'post',
          action:
            action + (message.redactedAt ? '?index&/unredactMessage' : '?index&/redactMessage'),
        },
      );

      onRedact?.(message, threadId);
    }

    function handleDelete(message: MessageFragment) {
      deleteMessageFetcher.submit(
        { id: message.id },
        { method: 'post', action: action + '?index&/deleteMessage' },
      );

      onDelete?.(message, threadId);
    }

    return (
      <>
        <MessageContainer
          key={message.id}
          id={message.id}
          className={cn(className, { 'rounded-lg ring-1': isHighlighted })}
          onClick={() => {
            onClick?.(message, threadId);
            replyOnClick && setCanReply(true);
            href && navigate(href);
          }}
          onMouseOver={() => href && setHover(true)}
        >
          {href ? <Link to={href} className="sr-only" prefetch="intent" /> : null}
          {hover && href ? <PrefetchPageLinks page={href} /> : null}
          <MessageSenderAvatar message={message} hideLine={hideLine} />
          <MessageHeader
            threadId={threadId}
            thread={thread}
            message={message}
            onLike={handleLike}
            onRedact={handleRedact}
            onDelete={() => setDeleteDialogOpen(true)}
            onEdit={() => setEditing((p) => !p)}
            onArchive={() => {
              fetcher.submit(null, {
                method: 'POST',
                action: action + (thread?.archivedAt ? '?index&/unarchive' : '?index&/archive'),
              });
              onArchive?.(message, threadId);
            }}
            onLock={() => {
              fetcher.submit(
                {},
                {
                  method: 'POST',
                  action: action + (thread?.lockedAt ? '?index&/unlock' : '?index&/lock'),
                },
              );
              onLock?.(message, threadId);
            }}
            onAcceptInCommunity={
              onAcceptInCommunity
                ? () => {
                    fetcher.submit(null, {
                      action: userAction + '?index&/acceptInCommunity',
                      method: 'POST',
                    });

                    onAcceptInCommunity(message, threadId);
                  }
                : undefined
            }
            onRejectFromCommunity={
              onRejectFromCommunity
                ? () => {
                    fetcher.submit(null, {
                      action: userAction + '?index&/rejectFromCommunity',
                      method: 'POST',
                    });

                    onRejectFromCommunity(message, threadId);
                  }
                : undefined
            }
            onBan={
              onBan
                ? () => {
                    fetcher.submit(null, {
                      action:
                        userAction +
                        (message.sender.bannedAt ? '?index&/unbanUser' : '?index&/banUser'),
                      method: 'POST',
                    });

                    onBan(message, threadId);
                  }
                : undefined
            }
          />
          {editing ? (
            <CardContent className="overflow-hidden pl-0">
              <MessageInput
                user={user}
                message={message}
                threadId={threadId}
                onCancel={() => setEditing(false)}
                onSubmit={() => {
                  setEditing(false);
                  onEdit?.(message, threadId);
                }}
                className="ml-1 mt-1"
              />
            </CardContent>
          ) : (
            <MessageContent message={message} thread={thread} />
          )}

          <MessageFooter message={message} thread={thread} />

          {replyOnClick && canReply ? (
            <MessageInput
              user={user}
              threadId={message.childThread?.id || threadId}
              onCancel={() => setCanReply(false)}
              onSubmit={() => {
                onReply?.();
                setCanReply(false);
              }}
              className="mb-4 mr-6"
              placeholder="Reply to this message..."
              parentMessageId={message.childThread?.id ? undefined : message.id}
            />
          ) : null}

          {message.childThread?.messages?.count ? (
            <ChildThread parentMessage={message} user={user} />
          ) : null}
        </MessageContainer>

        <AlertDialog open={deleteDialogOpen}>
          <AlertDialogContent>
            <AlertDialogHeader>
              <AlertDialogTitle>Delete message</AlertDialogTitle>
            </AlertDialogHeader>
            <AlertDialogDescription asChild>
              <>
                <p>Are you sure you want to delete this message?</p>
                <Linkify
                  as="p"
                  className="max-h-56 break-before-page overflow-y-auto rounded-md border p-3 text-sm font-light leading-loose text-foreground"
                  options={{
                    nl2br: true,
                    render: renderLink,
                    target: { url: '_blank' },
                  }}
                >
                  {message?.body?.trim()}
                </Linkify>
              </>
            </AlertDialogDescription>
            <AlertDialogFooter>
              <Button type="button" variant="outline" onClick={() => setDeleteDialogOpen(false)}>
                Cancel
              </Button>

              <Button
                variant="destructive"
                loading={deleteMessageFetcher.state === 'submitting'}
                onClick={() => handleDelete(message)}
              >
                Delete
              </Button>
            </AlertDialogFooter>
          </AlertDialogContent>
        </AlertDialog>
      </>
    );
  },
);

type O = Omit<Awaited<ReturnType<typeof loader>>, 'user'>;

function ChildThread({
  parentMessage,
  user,
}: {
  parentMessage: MessageFragment;
  user: UserSession;
}) {
  const [showReplies, setShowReplies] = React.useState(false);
  const { data, isValidating, setSize, mutate } = useSWRInfinite<O>(
    (pageIndex) =>
      showReplies ? `/api/threads/${parentMessage.childThread?.id}?page=${pageIndex}` : null,
    (url) => fetch(url).then((res) => res.json()),
    { refreshInterval: 5000 },
  );

  const hasNextPage = data?.at(-1)?.hasNextPage;
  type Data = typeof data;

  const handleMutate = React.useCallback(
    (msg: MessageFragment, mutator: (message: MessageFragment) => void) => {
      mutate<Data>(
        (prev) => {
          const pages = prev ? [...prev] : [];
          pagesLoop: for (const page of pages) {
            for (const message of page.messages) {
              if (message.id === msg.id) {
                mutator(message);
                break pagesLoop;
              }
            }
          }

          return pages;
        },
        { revalidate: false },
      );
    },
    [mutate],
  );

  const handleUserMutate = React.useCallback(
    (msg: MessageFragment, mutator: (message: MessageFragment) => void) => {
      const senderId = msg.sender.id;
      mutate<Data>(
        (prev) => {
          const pages = prev ? [...prev] : [];
          for (const page of pages) {
            for (const message of page.messages) {
              if (message.sender.id === senderId) {
                mutator(message);
              }
            }
          }

          return pages;
        },
        { revalidate: false },
      );
    },
    [mutate],
  );

  const handleLike = React.useCallback(
    (message: MessageFragment) =>
      handleMutate(message, (msg) => {
        msg.likeCount = Math.max(0, msg.likeCount + (message.liked ? -1 : 1));
        msg.liked = !message.liked;
      }),
    [handleMutate],
  );

  const handleRedact = React.useCallback(
    (message: MessageFragment) =>
      handleMutate(message, (msg) => {
        if (msg.redactedAt) msg.redactedAt = null;
        else msg.redactedAt = new Date().toISOString();
      }),
    [handleMutate],
  );

  const handleDelete = React.useCallback(
    (message: MessageFragment) =>
      handleMutate(message, (msg) => {
        msg.deletedAt = new Date().toISOString();
      }),
    [handleMutate],
  );

  const handleBan = React.useCallback(
    (msg: MessageFragment, _threadId: string) => {
      handleUserMutate(msg, (prev) => {
        prev.sender.bannedAt = prev.sender.bannedAt != null ? null : new Date().toISOString();
      });
    },
    [handleUserMutate],
  );

  const handleAcceptInCommunity = React.useCallback(
    (msg: MessageFragment, _threadId: string) => {
      handleUserMutate(msg, (prev) => {
        prev.sender.communityVettingStatus = 'ACCEPTED';
      });
    },
    [handleUserMutate],
  );

  const handleRejectFromCommunity = React.useCallback(
    (msg: MessageFragment, _threadId: string) => {
      handleUserMutate(msg, (prev) => {
        prev.sender.communityVettingStatus = 'REJECTED';
      });
    },
    [handleUserMutate],
  );

  const handleEdit = React.useCallback(
    (message: MessageFragment) =>
      handleMutate(message, (msg) => {
        msg.body = message.body;
      }),
    [handleMutate],
  );

  if (!data?.length) {
    return (
      <ShowRepliesButton
        loading={isValidating}
        onClick={(e) => {
          e.stopPropagation();
          if (isValidating) return;
          if (!showReplies) setShowReplies(true);
          else setSize((s) => s + 1);
        }}
      />
    );
  }

  return (
    <div className="col-span-1 col-start-2 -ml-5 mb-4" onClick={(e) => e.stopPropagation()}>
      {data?.map(({ messages }, j) => (
        <Card key={j} className="flex w-full flex-col border-none shadow-none">
          {messages.map((message, i) => (
            <Message
              threadId={parentMessage.childThread?.id || ''}
              key={message.id}
              className="-mt-4"
              user={user}
              hideLine={!hasNextPage && i === messages.length - 1 && j === data.length - 1}
              message={message}
              onDelete={handleDelete}
              onRedact={handleRedact}
              onLike={handleLike}
              onEdit={handleEdit}
              onBan={handleBan}
              onAcceptInCommunity={handleAcceptInCommunity}
              onRejectFromCommunity={handleRejectFromCommunity}
            />
          ))}
        </Card>
      ))}

      {hasNextPage ? (
        <ShowRepliesButton
          loading={isValidating}
          onClick={(e) => {
            e.stopPropagation();
            if (isValidating) return;
            if (!showReplies) setShowReplies(true);
            else setSize((s) => s + 1);
          }}
        />
      ) : null}
    </div>
  );
}

function ShowRepliesButton({
  loading,
  ...props
}: { loading: boolean } & React.ComponentPropsWithRef<'div'>) {
  return (
    <div
      {...props}
      className={cn(
        'group col-span-full grid cursor-pointer grid-cols-[80px_1fr] transition-colors ease-in-out hover:bg-muted',
        props.className,
      )}
    >
      <div className="mx-auto h-full w-0 border-l-4 border-dotted border-muted py-1 group-hover:border-background" />
      <div>
        <Button variant="link" size="sm" className="pl-0 hover:no-underline" loading={loading}>
          Show Replies
        </Button>
      </div>
    </div>
  );
}
