import type { ThreadType } from '~/generated/club.server';

export function getThreadTypeLabel(type: ThreadType) {
  switch (type) {
    case 'ACTIVITY':
      return '🪁 Activity';
    case 'SYSTEM':
      return '🤖 Colette Bot';
    case 'BASE':
      return '💬 Discussion';
    case 'DISCUSSION_GROUP':
      return '💬 Discussion';
    case 'FEED_ITEM':
      return '🗞️ Feed Item';
    case 'PRIVATE':
      return '🕵️‍♀️ Private';
    case 'CONTENT_LIBRARY':
      return '🎬 Library';
    case 'REPLY':
      return '🔂 Message Reply';
    default:
      return null;
  }
}
