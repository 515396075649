import useSWRInfinite from 'swr/infinite';
import { A } from '~/components/A';
import { Avatar } from '~/components/Avatar';
import { Button } from '~/components/Button';
import { HoverCard, HoverCardContent, HoverCardTrigger } from '~/components/HoverCard';
import { Loader } from '~/components/Loader';
import type { ThreadParticipantsQuery } from '~/generated/club.server';
import { connectionResultToNodeArray } from '~/utils/connection-result-to-node-array';
import { formatUser } from '~/utils/user';

interface Props {
  open?: boolean;
  children: React.ReactNode;
  route: string;
}

export const UsersHoverCard = ({ open, children, ...props }: Props) => {
  return (
    <HoverCard openDelay={50} open={open}>
      <HoverCardTrigger>{children}</HoverCardTrigger>
      <HoverCardContent>
        <Content {...props} />
      </HoverCardContent>
    </HoverCard>
  );
};

const Content = ({ route }: Omit<Props, 'open' | 'children'>) => {
  const { data, isValidating, isLoading, setSize } = useSWRInfinite<
    ThreadParticipantsQuery['thread']['participants']
  >(
    (i) => `${route}?page=${i}`,
    (url) => fetch(url).then((res) => res.json().then((d) => d.users)),
  );

  const hasNextPage = data?.at(-1)?.pageInfo?.hasNextPage;

  return (
    <div className="grid max-h-[500px] auto-rows-auto gap-4 overflow-y-auto">
      {isLoading ? <Loader /> : null}
      {data?.map((page) =>
        connectionResultToNodeArray(page).map((user) => (
          <div key={user.id} className="flex items-center gap-2">
            <Avatar key={user.id} size="xs" user={user} disableMenu />
            <A to={`/users/${user.id}`}>{formatUser(user)}</A>
          </div>
        )),
      )}

      {hasNextPage ? (
        <Button
          variant="outline"
          loading={isValidating}
          onClick={(e) => {
            e.stopPropagation();
            setSize((s) => s + 1);
          }}
        >
          Show more
        </Button>
      ) : null}
    </div>
  );
};
