import dayjs from 'dayjs';
import Autoplay from 'embla-carousel-autoplay';
import Linkify from 'linkify-react';
import plur from 'plur';
import { ResponsiveContainer, Bar, BarChart, XAxis, YAxis } from 'recharts';
import { useInterval } from 'usehooks-ts';
import { useLoaderData, useNavigate, useRevalidator } from '@remix-run/react';
import type { LoaderFunctionArgs } from '@remix-run/server-runtime';
import { A } from '~/components/A';
import { Avatar } from '~/components/Avatar';
import { Card, CardContent, CardDescription, CardHeader, CardTitle } from '~/components/Card';
import { Carousel, CarouselContent, CarouselIndicator, CarouselItem } from '~/components/Carousel';
import { Bed, Heart, HeartHandshake, ImageOff, Smile, Users } from 'lucide-react';
import { Image } from '~/components/Image';
import { dashboardQuery } from '~/generated/club.server';
import { useIdle } from '~/hooks/use-idle';
import { Layout, LayoutHeader, LayoutHeaderContent, LayoutTitle } from '~/layouts';
import { getAreaLabel } from '~/utils/area';
import { requireViewerSession } from '~/utils/auth.server';
import { cn } from '~/utils/cn';
import { connectionResultToNodeArray } from '~/utils/connection-result-to-node-array';
import { formatUser } from '~/utils/user';
import { LINKIFY_OPTIONS } from './_c.discussions.threads._index/Message';

export const handle = {
  breadcrumb: () => 'Dashboard',
};

export async function loader(ctx: LoaderFunctionArgs) {
  await requireViewerSession(ctx.request);

  const date = dayjs();

  const {
    bannedUsers,
    activitiesParis,
    activitiesLyon,
    activitiesBordeaux,
    activitiesMarseille,
    activitiesNice,
    activitiesToulouse,
    activitiesOther,
    activitiesSubmittedForValidation,
    lastMessages,
    reportedMessages,
    popularThreads,
    ...response
  } = await dashboardQuery(ctx.request, {
    from: date.startOf('month').format('YYYY-MM-DD'),
    to: date.endOf('month').format('YYYY-MM-DD'),
    now: date.format('YYYY-MM-DD'),
    toString: date.endOf('month').format('YYYY-MM-DD'),
  });

  return {
    ...response,
    bannedUsers: connectionResultToNodeArray(bannedUsers),
    activitiesSubmittedForValidation: connectionResultToNodeArray(activitiesSubmittedForValidation),
    lastMessages: connectionResultToNodeArray(lastMessages),
    reportedMessages: connectionResultToNodeArray(reportedMessages),
    popularThreads: connectionResultToNodeArray(popularThreads),
    activitiesByarea: [
      {
        name: '🥖',
        total: activitiesParis.count,
      },
      {
        name: '🦁',
        total: activitiesLyon.count,
      },
      {
        name: '🍷',
        total: activitiesBordeaux.count,
      },
      {
        name: '☀️',
        total: activitiesMarseille.count,
      },
      {
        name: '⛵️',
        total: activitiesNice.count,
      },
      {
        name: '🏉',
        total: activitiesToulouse.count,
      },
      {
        name: '🌍',
        total: activitiesOther.count,
      },
    ],
  };
}

export default function Dashboard() {
  const data = useLoaderData<typeof loader>();
  const navigate = useNavigate();

  const revalidator = useRevalidator();
  const idle = useIdle();

  useInterval(
    () => {
      revalidator.revalidate();
    },
    idle ? null : 5000,
  );

  return (
    <Layout className="flex h-full flex-col md:max-h-[calc(100dvh_-_60px)] md:min-h-[calc(100dvh_-_60px)] 2xl:px-0">
      <LayoutHeader>
        <LayoutHeaderContent>
          <LayoutTitle>Dashboard</LayoutTitle>
        </LayoutHeaderContent>
      </LayoutHeader>

      <main className="flex h-full flex-1 flex-col gap-4 px-4 pb-8">
        <div className="grid h-auto auto-rows-min gap-4 md:grid-cols-2 xl:grid-cols-4">
          {[
            {
              title: 'House-shares',
              icon: HeartHandshake,
              value: data.bookings.count || 0,
              description: `${data.bookingRelocations.count || 0} ${plur(
                'relocation',
                data.bookingRelocations.count || 0,
              )} and ${data.bookingMoves.count || 0} ${plur(
                'move',
                data.bookingMoves.count || 0,
              )} this month`,
            },
            {
              title: 'Published Rooms',
              icon: Bed,
              value: data.accommodations.count || 0,
              description: `${data.accommodationsAvailable.count || 0} ${plur(
                'accommodation',
                data.accommodationsAvailable.count || 0,
              )} available this month`,
            },
            {
              title: 'Published Activities',
              icon: Smile,
              value: data.activities.count || 0,
              description: `${data.upcommingActivities.count || 0} ${plur(
                'activity',
                data.upcommingActivities.count || 0,
              )} scheduled this month`,
            },
            {
              title: 'Published Activities by the community',
              icon: Users,
              value: data.community.count || 0,
              description: `${
                (data.activities.count || 0) - (data.community.count || 0)
              } by Colette`,
            },
          ].map((stat) => (
            <CardStats key={stat.title} {...stat} />
          ))}
        </div>

        <div className="grid h-full gap-4 md:grid-cols-3 xl:flex-1 xl:grid-cols-[repeat(18,_minmax(0,_1fr))] xl:grid-rows-12">
          <Card className="overflow-hidden xl:col-span-5 xl:col-start-1 xl:row-span-5 xl:row-start-1">
            <CardHeader>
              <CardTitle>Popular Threads</CardTitle>
            </CardHeader>
            <CardContent className="pb-0">
              <Carousel className="w-full" plugins={[Autoplay({ delay: 4000 })]}>
                <CarouselContent>
                  {data.popularThreads.map((thread) => (
                    <CarouselItem
                      key={thread.id}
                      onClick={() =>
                        navigate(
                          '/discussions/threads/' + thread.id + `#${thread.firstMessage?.id}`,
                        )
                      }
                      className="cursor-pointer"
                    >
                      <Card>
                        <CardContent className="grid grid-cols-[min-content_auto] gap-6 p-3">
                          <div className="flex flex-col items-center justify-between gap-6">
                            <Avatar size="sm" user={thread.firstMessage?.sender} />

                            <div className="flex flex-col gap-1">
                              <span className="flex items-center gap-1 text-xs text-foreground">
                                <Users className="size-3" />
                                {thread.participants?.count || 0}
                              </span>

                              <span className="flex items-center gap-1 text-xs text-foreground">
                                <Heart className="size-3" />
                                {thread.firstMessage?.likeCount || 0}
                              </span>
                            </div>
                          </div>
                          <Linkify
                            as="span"
                            className="line-clamp-6 break-before-page text-sm font-light leading-relaxed text-foreground"
                            options={LINKIFY_OPTIONS}
                          >
                            {thread.firstMessage?.body?.trim()}
                          </Linkify>
                        </CardContent>
                      </Card>
                    </CarouselItem>
                  ))}
                </CarouselContent>
                <CarouselIndicator className="pb-4 xl:pb-0" />
              </Carousel>
            </CardContent>
          </Card>

          <Card className="xl:col-span-5 xl:col-start-6 xl:row-span-5 xl:row-start-1">
            <CardHeader>
              <CardTitle>Area-wise activity totals</CardTitle>
              <CardDescription>
                Showcasing activities published across different areas
              </CardDescription>
            </CardHeader>
            <CardContent className="h-full max-h-44 p-6 pb-4 pl-0 pt-0">
              <ResponsiveContainer width="100%">
                <BarChart data={data.activitiesByarea}>
                  <XAxis
                    dataKey="name"
                    stroke="#888888"
                    fontSize={14}
                    tickLine={false}
                    axisLine={false}
                  />
                  <YAxis stroke="#888888" fontSize={12} tickLine={false} axisLine={false} />
                  <Bar
                    dataKey="total"
                    fill="currentColor"
                    radius={[4, 4, 0, 0]}
                    className="fill-primary"
                    label={{ position: 'top', fontSize: 10 }}
                  />
                </BarChart>
              </ResponsiveContainer>
            </CardContent>
          </Card>

          <Card className="xl:col-span-3 xl:col-start-11 xl:row-span-5 xl:row-start-1">
            <CardHeader>
              <CardTitle>Banned Users</CardTitle>
            </CardHeader>
            <CardContent className="flex flex-col gap-4">
              {data.bannedUsers.map((user) => (
                <div key={user.id} className="flex items-center gap-4">
                  <Avatar size="sm" user={user} />
                  <A to={'/users/' + user.id} className="line-clamp-1 font-medium">
                    {formatUser(user)}
                  </A>
                </div>
              ))}
            </CardContent>
          </Card>

          <Card className="xl:col-span-5 xl:col-end-[19] xl:row-span-full">
            <CardHeader>
              <CardTitle>Last Messages</CardTitle>
            </CardHeader>
            <CardContent className="flex flex-col gap-4">
              {data.lastMessages.map((message) => (
                <Message key={message.id} message={message} />
              ))}
            </CardContent>
          </Card>

          <Card className="xl:col-span-6 xl:col-start-8 xl:row-span-full xl:row-start-6">
            <CardHeader>
              <CardTitle>Activities awaiting validation</CardTitle>
            </CardHeader>
            <CardContent className="flex flex-col gap-5">
              {data.activitiesSubmittedForValidation.map((activitiy) => (
                <div key={activitiy.id} className="flex items-center gap-4">
                  {activitiy.picture?.url ? (
                    <Image
                      alt={activitiy.name}
                      src={activitiy.picture?.url}
                      height={38}
                      width={38}
                      className="size-[38px] rounded-sm object-cover"
                    />
                  ) : (
                    <div className="grid aspect-square size-[38px] place-content-center rounded-sm bg-muted">
                      <ImageOff className="size-6 text-muted-foreground" />
                    </div>
                  )}
                  <div>
                    <A
                      className="line-clamp-1 text-sm font-medium"
                      to={'/activities/' + activitiy.id}
                    >
                      {activitiy.name}
                    </A>
                    <div className="flex items-center gap-2 text-xs font-medium text-muted-foreground">
                      <span className="flex items-center whitespace-nowrap">
                        <Avatar size="2xs" className="mr-2" user={activitiy.creator} />
                        {formatUser(activitiy.creator)}
                      </span>
                      &bull;
                      <span className="line-clamp-1">
                        {activitiy.areas.map((area) => getAreaLabel(area, true)).join(', ')}
                      </span>
                    </div>
                  </div>
                </div>
              ))}
            </CardContent>
          </Card>

          <Card className="xl:col-span-7 xl:col-start-1 xl:row-span-full xl:row-start-6">
            <CardHeader>
              <CardTitle>Reported Messages</CardTitle>
            </CardHeader>
            <CardContent className="flex flex-col gap-4">
              {data.reportedMessages.map((message) => (
                <Message key={message.id} message={message} />
              ))}
            </CardContent>
          </Card>
        </div>
      </main>
    </Layout>
  );
}

function CardStats({
  title,
  value,
  icon: Icon,
  description,
}: {
  title: React.ReactNode;
  value: React.ReactNode;
  icon: React.ElementType;
  description?: React.ReactNode;
}) {
  return (
    <Card>
      <CardHeader className="flex flex-row items-center justify-between space-y-0 pb-2">
        <CardTitle className="text-sm font-medium">{title}</CardTitle>
        <Icon strokeWidth="2" className="size-4 text-muted-foreground" />
      </CardHeader>
      <CardContent>
        <div className="text-2xl font-bold">{value}</div>
        {description ? <p className="text-xs text-muted-foreground">{description}</p> : null}
      </CardContent>
    </Card>
  );
}

function Message({
  message,
}: {
  message: Awaited<ReturnType<typeof loader>>['lastMessages'][number];
}) {
  const navigate = useNavigate();

  return (
    <Card
      onClick={() => navigate('/discussions/threads/' + message.thread.id + `#${message.id}`)}
      className="cursor-pointer"
    >
      <CardContent className="flex items-center gap-4 p-2">
        <Avatar size="sm" user={message.sender} />
        <p className={cn('line-clamp-2 text-sm', { 'line-through': message.deletedAt != null })}>
          {message.body}
        </p>
      </CardContent>
    </Card>
  );
}
